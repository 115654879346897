/* HEAD IMAGE */
.head-img {
	max-height: 420px;
	overflow: hidden;
	position: relative;
	img.serv-head {
		margin-top: -16%;
		max-width: 100%;
	}
	.text-wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		text-align: center;
		width: 100%;
		p.title {
			font-size: 50px;
			color: #fff;
			font-family: $titlefont;
			font-weight: 700;
			text-transform: uppercase;
		}
		@media (max-width: 580px) {
			p.title {
				font-size: 50px;
			}
		}
		@media (max-width: 430px) {
			p.title {
				font-size: 40px;
			}
		}
		@media (max-width: 330px) {
			p.title {
				font-size: 30px;
			}
		}
	}
}

/* SERVICES */
.service-serv {
	padding: 80px 0 100px;
	background-color: #fff;
	img.serv-img {
		max-width: 100%;
		float: right;
	}
	.text-wrapper {
		padding: 80px 60px;
		.section-title {
			font-size: 20px;
			margin-bottom: 10px;
		}
		.body-text {
			text-align: center;
			margin-bottom: 30px;
		}
		a {
			display: block;
			margin: auto;
			text-align: center;
			font-size: 13px;
			color: $brown;
			text-transform: uppercase;
			border-bottom: 2px solid $brown;
			width: 74px;
			&:hover {
				color: $lightcolor;
				border-bottom: 2px solid $lightcolor;
			}
		}
		@media(max-width: 1080px) {
			padding: 60px 40px;
		}
		@media(max-width: 940px) {
			padding: 44px 20px;
			.body-text {
				margin-bottom: 24px;
			}
		}
		@media(max-width: 840px) {
			padding: 14px 20px;
		}
		@media(max-width: 768px) {
			padding: 50px 80px;
		}
		@media(max-width: 460px) {
			padding: 50px 20px;
		}
		@media(max-width: 430px) {
			padding: 50px 0;
		}
	}
	@media(max-width: 768px) {
		.col-1-2 {
			text-align: center;
		}
	}
	@media(max-width: 768px) {
		padding: 30px 0 80px;
		img.serv-img {
			float: none;
		}
	}
}

/* SERVICES SECTION */
.services-section {
	background-color: #fff;
	.title-and-desc {
		@media(max-width: 768px) {
			margin-bottom: 80px;
		}
	}
}

.service-2 {
	background-color: $gray;
	img.serv-img {
		float: left;
		@media(max-width: 768px) {
			float: none;
		}
	}
}

