/* HEAD IMAGE */
.head-img {
	max-height: 420px;
	overflow: hidden;
	position: relative;
	img.about-head {
		margin-top: -19%;
		max-width: 100%;
	}
	.text-wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		text-align: center;
		width: 100%;
		p.title {
			font-size: 50px;
			color: #fff;
			font-family: $titlefont;
			font-weight: 700;
			text-transform: uppercase;
		}
		@media (max-width: 580px) {
			p.title {
				font-size: 50px;
			}
		}
		@media (max-width: 430px) {
			p.title {
				font-size: 40px;
			}
		}
		@media (max-width: 330px) {
			p.title {
				font-size: 30px;
			}
		}
	}
}

/* OUR STORY SECTION */
.our-story {
	padding: 80px 0 100px;
	background-color: #fff;
	.text-wrapper {
		padding: 110px 44px;
		.body-text {
			text-align: center;
		}
		@media(max-width: 940px) {
			padding: 80px 0;
		}
		@media(max-width: 860px) {
			padding: 50px 0px;
		}
		@media(max-width: 768px) {
			padding: 50px 50px;
		}

	}
	@media(max-width: 768px) {
		.col-1-2 {
			text-align: center;
		}
	}
	@media(max-width: 768px) {
		padding: 30px 0 80px;
	}
}

/* SERVICES SECTION */
.services-section.serv-2 {
	background-color: $gray;
	padding: 80px 0 30px;
	.title-and-desc {
		@media(max-width: 768px) {
			margin-bottom: 80px;
		}
	}
}

