/* HEAD IMAGE */
.head-img {
	max-height: 420px;
	overflow: hidden;
	position: relative;
	img.work-head {
		margin-top: -10%;
		max-width: 100%;
	}
	.text-wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		text-align: center;
		width: 100%;
		p.title {
			font-size: 50px;
			color: #fff;
			font-family: $titlefont;
			font-weight: 700;
			text-transform: uppercase;
		}
		@media (max-width: 580px) {
			p.title {
				font-size: 50px;
			}
		}
		@media (max-width: 430px) {
			p.title {
				font-size: 40px;
			}
		}
		@media (max-width: 330px) {
			p.title {
				font-size: 30px;
			}
		}
	}
}

.btn {
	display: block;
	margin: auto;
	margin-top: 50px;
	text-align: center;
}



