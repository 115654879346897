// Css base
@import 'core/vars';
@import 'core/reset';
@import 'core/grid';



//Site
@import 'site/site';
@import 'site/home'; 
@import 'site/about'; 
@import 'site/work'; 
@import 'site/services'; 
@import 'site/contact'; 
@import 'site/projectpage'; 

 


// Vendors -- PLUGINS
@import 'vendors/slicknav';
// @import 'vendors/flexslider';
 
