/* HEAD IMAGE */
.head-img {
	max-height: 420px;
	overflow: hidden;
	position: relative;
	img.project-head {
		margin-top: -16%;
		max-width: 100%;
	}
	.text-wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		text-align: center;
		width: 100%;
		p.title {
			font-size: 50px;
			color: #fff;
			font-family: $titlefont;
			font-weight: 700;
			text-transform: uppercase;
		}
		@media (max-width: 580px) {
			p.title {
				font-size: 50px;
			}
		}
		@media (max-width: 430px) {
			p.title {
				font-size: 40px;
			}
		}
		@media (max-width: 330px) {
			p.title {
				font-size: 30px;
			}
		}
	}
}

/* CONTAINER */
.container-sm {
	max-width: 800px;
	margin: 0 auto;
	padding-left: 15px;
	padding-right: 15px;
}

/* PROJECT SECTION */
.project {
	padding: 60px 0 70px;
	background-color: #fff;
	.section-title {
		font-size: 20px;
		margin-bottom: 34px;
	}
	.project-info {
		background-color: #fafafa;
		height: 100%;
		padding: 30px 20px;
		margin-bottom: 50px;
		p {
			font-size: 13px;
			color: #616161;
			.title {
				font-size: 15px;
				font-family: $titlefont;
				font-weight: 700;
				margin-right: 10px;
			}
		}
		.date {
			text-align: left;
		}
		.customer {
			text-align: center;
		}
		.price {
			text-align: right;
		}
		@media(max-width: 768px) {
			.col-1-3 {
				width: 33.3333333333%;
			}
		}
		@media(max-width: 810px) {
			padding: 46px 20px;
			.col-1-3 {
				width: 100%;
				p {
					text-align: left;
					margin-bottom: 20px;
				}
				.price {
					margin-bottom: 0;
				}
			}
		}
	}
	.body-text {
		margin-bottom: 26px;
		text-align: center;
		line-height: 24px;
	}
	.body-text.text {
		margin-bottom: 60px;
	}
	.body-text.text-2 {
		margin-bottom: 40px;
	}
	img {
		width: 100%;
		margin-bottom: 40px;
	}
	.description {
		background-color: #fff3e0;
		height: 100%;
		padding: 30px 20px;
		margin-bottom: 50px;
		margin-bottom: 40px;
		.body-text {
			margin-bottom: 0;
		}
	}
	.section-title.title {
		margin-bottom: 12px;
	}
	.divider {
		background-color: #eeeeee;
		height: 2px;
		display: block;
		margin-bottom: 40px;
	}
	.navigation {
		position: relative;
		font-size: 12px;
		text-transform: uppercase;
		.all-work {
			text-align: center;
			color: $darkcolor;
			display: block;
			width: 100px;
			margin: 24px auto 0;
			&:hover {
				color: $brown;
				transition: .4s;
			}
			i {
				font-size: 40px;
				line-height: 0;
			}
			span {
				font-size: 14px;
				font-family: $titlefont;
				font-weight: 700;
				display: block;
			}
		}
		.prev {
			position: absolute;
			top: 10px;
			left: 0;
			font-weight: 700;
		}
		.next {
			position: absolute;
			top: 10px;
			right: 0;
			font-weight: 700;
		}
		a {
			color: $darkcolor;
			&:hover {
				color: $brown;
				transition: .4s;
			}
		}
	}
}



