// Fonts
$titlefont 	: "Play" ;
$bodyfont	: "Montserrat" ;

// FONT COLORS
$lightcolor	: #616161;
$darkcolor	: #28292e;
$brown		: #ffcc80;


// BACKROUND COLORS
$white	: #fff;
$gray	: #fafafa;


// FONT SIZES
$titlesize	: 30px;
$textsize	: 13px;

// Font weight 
$light 	: 300 ;
$normal : 400 ;
$semi 	: 600;
$bold 	: 700;
$bolder : 900;

// Media Queries
$mediaRe : 320px;
$mediaXs : 480px;
$mediaSm : 768px;
$mediaMd : 960px;
$mediaLa : 1200px;

 