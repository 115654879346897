/* HEAD IMAGE */
.head-img {
	max-height: 420px;
	overflow: hidden;
	position: relative;
	img.contact-head {
		margin-top: -16%;
		max-width: 100%;
	}
	.text-wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		text-align: center;
		width: 100%;
		p.title {
			font-size: 50px;
			color: #fff;
			font-family: $titlefont;
			font-weight: 700;
			text-transform: uppercase;
		}
		@media (max-width: 580px) {
			p.title {
				font-size: 50px;
			}
		}
		@media (max-width: 430px) {
			p.title {
				font-size: 40px;
			}
		}
		@media (max-width: 330px) {
			p.title {
				font-size: 30px;
			}
		}
	}
}

/* SERVICES */
.get-in-touch {
	padding: 100px 0 50px;
	background-color: #fff;
	img {
		max-width: 100%;
		float: left;
	}
	.text-wrapper {
		padding: 80px 0px 80px 40px;
		.section-title {
			text-align: left;
		}
		.body-text {
			text-align: left;
			margin-bottom: 40px;
			border-bottom: 1px solid #e0e0e0;
			padding-bottom: 30px;
		}
		.body-text.contact-info {
			border: none;
			position: relative;
			padding-left: 36px;
			margin-bottom: 0;
			i {
				position: absolute;
				top: 0;
				left: 0;
				font-size: 22px;
				color: $darkcolor;
			}
		}
		@media(max-width: 1180px) {
			padding: 60px 20px;
		}
		@media(max-width: 1080px) {
			padding: 40px 20px;
		}
		@media(max-width: 1030px) {
			padding: 0px 20px;
		}
		@media(max-width: 930px) {
			padding: 0px 20px;
			.body-text {
				padding-bottom: 16px;
				margin-bottom: 20px;
			}
		}
		@media(max-width: 768px) {
			padding: 80px 0px 20px;
		}
	}
	@media(max-width: 768px) {
		.col-1-2 {
			text-align: center;
		}
	}
	@media(max-width: 768px) {
		padding: 80px 0 40px;
		img {
			float: none;
		}
	}
}

/* MESSAGE FORMS SECTION */
.message-form-section {
	padding: 80px 0;
	background-color: $gray;
	.description {
		margin-bottom: 50px;
		.body-text {
			text-align: center;
		}
	}
	.inputs {
		input[type=text] {
			width: 300px;
			max-width: 100%;
			height: 48px;
			border-radius: 3px;
			border: none;
			background-color: #fff;
			outline: none;
			padding-left: 20px;
			font-size: 13px;
			font-family: $titlefont;
			color: $darkcolor;
			margin: auto;
			display: block;
			margin-bottom: 30px;
		}
		.input-left input {
			float: right;
		}
		.input-right input {
			float: left;
		}
		.input-msg textarea {
			width: 630px;
			max-width: 100%;
			height: 200px;
			border-radius: 3px;
			border: none;
			background-color: #fff;
			outline: none;
			padding-left: 20px;
			padding-top: 18px;
			font-size: 13px;
			font-family: $titlefont;
			color: $darkcolor;
			margin: auto;
			display: block;
			margin-bottom: 30px;
			resize: none;
		}
		.submit-btn input {
			width: 630px;
			max-width: 100%;
			height: 48px;
			border-radius: 3px;
			border: none;
			background-color: #2196f3;
			outline: none;
			padding-left: 20px;
			font-size: 14px;
			font-family: $titlefont;
			color: #fff;
			margin: auto;
			display: block;
			margin-bottom: 30px;
			resize: none;
			&:hover {
				opacity: .7;
			}
		}
	}
}

@media(max-width: 768px) {
	.message-form-section {
		.col-1-2 {
			width: 50%;
		}
	}
}
@media(max-width: 480px) {
	.message-form-section {
		.col-1-2 {
			width: 100%;
		}
		.inputs {
			.input-left input, .input-right input {
				float: none;
				width: 100%;
			}
		}
	}
}

/* GOOGLE MAPS */
#googleMap {
    width: 100%;
    height: 400px;
    background-color: grey;
}

