/* FONTS */
@import url('https://fonts.google.com/specimen/Play');
@import url('https://fonts.google.com/specimen/Montserrat');

/* OVERALL */
a {
	text-decoration: none;
}

body {
	font-family: $bodyfont;
}

img {
	vertical-align: middle;
}

/* NAVBAR */
.main-nav {
	background-color: #fff;
	padding: 24px 0;
	@media (max-width: 850px) {
			padding: 0;
		}
	h1.logo {
		a {
			color: #002829;
		}
		position: relative;
		padding-left: 40px;
		font-family: $bodyfont;
		font-size: 20px;
		img {
			position: absolute;
			left: 0;
			top: -4px;
		}
		@media (max-width: 850px) {
			top: 14px;
			position: absolute;
		}
	}
	.main-menu {
		text-align: right;
		font-family: $bodyfont;
		li {
			display: inline-block;
			margin-left: 30px;
			&:first-child {
				margin-left: 0;
			}
			a {
				color: $darkcolor;
				font-size: 11px;
				text-transform: uppercase;
				&:hover {
					color: $brown;
					transition: .3s;
				}
				&.active {
					color: $brown;
				}
			}
		}
		@media (max-width: 850px) {
			display: none;
		}
	}
}



/* SECTION TITLE STYLE */
.section-title, .section-title .separate {
	font-family: $titlefont;
	font-size: $titlesize;
	font-weight: 700;
	color: $darkcolor;
	text-align: center;
	line-height: 42px;
	text-transform: uppercase;
}
.section-title {
	margin-bottom: 12px;
}

/* BODY TEXT STYLE */
.body-text {
	font-size: $textsize;
	color: $lightcolor;
	line-height: 20px;
}

/* BUTTON */
.btn {
		width: 152px;
		max-width: 100%;
		height: 36px;
		background-color: transparent;
		border: 1px solid $brown;
		border-radius: 3px;
		font-family: $bodyfont;
		color: $brown;
		font-size: 12px;
		text-transform: uppercase;
		outline: none;
		padding: 10px 0;
		&:hover {
			background-color: $brown;
			color: #fff;
			transition: .5s;
		}
	}

/* FOOTER */
footer {
	padding: 46px 0 30px;
	background-color: #28292e;
	text-align: center;
	.title {
		font-size: 13px;
		text-transform: uppercase;
		color: #fff;
		margin-bottom: 10px;
	}
	img {
		margin-bottom: 8px;
	}
	.body-text {
		color: #fff;
	}
	.body-text.last {
		margin-bottom: 44px;
	}
	.social-media-footer {
		margin-bottom: 40px;
		a {
			font-size: 24px;
			color: #fff;
			margin-right: 36px;
			&:last-child {
				margin-right: 0;
			}
			&:hover {
				color: $brown;
			}
		}
	}
	.copyright {
		font-size: 11px;
		color: #93948f;
	}
}