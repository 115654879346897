/* FONTS */
@import url('https://fonts.google.com/specimen/Play');
@import url('https://fonts.google.com/specimen/Montserrat');

/* HERO IMG */
.hero-img {
	max-height: 702px;
	overflow: hidden;
	position: relative;
	img.hero {
		margin-top: -8%;
	}
	.text-wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		text-align: center;
		width: 100%;
		p.title {
			font-size: 60px;
			color: $brown;
		}
		p.description {
			font-size: 42px;
			color: #fff;
			margin-bottom: 20px;
		}
		.btn {
			width: 152px;
			max-width: 100%;
			height: 36px;
			background-color: transparent;
			border: 1px solid $brown;
			border-radius: 3px;
			font-family: $bodyfont;
			color: $brown;
			font-size: 12px;
			text-transform: uppercase;
			&:hover {
				background-color: $brown;
				color: #fff;
				transition: .5s;
			}
		}
		@media (max-width: 580px) {
			p.title {
				font-size: 50px;
			}
			p.description {
				font-size: 32px;
			}
		}
		@media (max-width: 430px) {
			p.title {
				font-size: 40px;
			}
			p.description {
				font-size: 22px;
			}
		}
		@media (max-width: 330px) {
			p.title {
				font-size: 30px;
			}
			p.description {
				font-size: 18px;
			}
		}
	}
}

/* SERVICES SECTION */
.services-section {
	padding: 80px 0 30px;
	background-color: #fff;
	.title-and-desc {
		margin-bottom: 110px;
		.separate {
			display: block;
		}
		p.body-text {
			text-align: center;
		}
	}
	.service {
		text-align: center;
		margin-bottom: 70px;
		img {
			margin-bottom: 6px;
		}
		.serv-title {
			font-size: 14px;
			font-family: $titlefont;
			color: $darkcolor;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 14px;
		}
	}
	// RESPONSIVE
	@media (max-width: 960px) {
		.col-1-3 {
			width: 50%;
		}
	}
	@media (max-width: 768px) {
		.col-1-3 {
			width: 100%;
		}
	}
}

/* LATEST WORK SECTION */
.latest-work {
	padding: 80px 0 60px;
	background-color: $gray;
	.body-text {
		text-align: center;
		&:last-of-type {
			margin-bottom: 110px;
		}
	}
	a {
		display: block;
		&:hover .img-wrapper .img-hover {
			background-color: rgba(255,255,255,.6);
			transition: .4s;
			.caption-box {
				opacity: 1;
				transition: .4s;
			}
		}
		.img-wrapper {
			position: relative;
			margin-bottom: 30px;
			img {
				width: 100%;
			}
			.img-hover {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(255,255,255,0);
				text-align: center;
				.caption-box {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					opacity: 0;
					i {
						font-size: 40px;
						color: $darkcolor;
						line-height: 0;
					}
					.caption-text {
						font-size: 13px;
						color: $darkcolor;
						text-transform: uppercase;
					}
				}
			}
		}
	}
	@media(max-width: 768px) {
		.col-1-2 {
			.img-wrapper {
				margin-bottom: 20px;
			}
		}
	}
	.all-work {
		text-align: center;
		color: $darkcolor;
		display: block;
		width: 100px;
		margin: 24px auto 0;
		&:hover {
			color: $brown;
			transition: .4s;
		}
		i {
			font-size: 40px;
			line-height: 0;
		}
		span {
			font-size: 14px;
			font-family: $titlefont;
			font-weight: 700;
			display: block;
		}
	}
 }

 /* OUR TEAM SECTION */
 .our-team {
 	padding: 80px 0;
 	background-color: #fff;
 	.body-text {
 		text-align: center;
 		&:last-of-type {
 			margin-bottom: 110px;
 		}
 	}
 	.member-wrap {
 		img {
 			width: 100%;
 		}
 		.info-container {
 			text-align: center;
 			border: 1px solid #f9f9f9;
 			padding: 10px 0 14px;
 			.name {
 				font-size: 14px;
 				color: $darkcolor;
 				text-transform: uppercase;
 				line-height: 30px;
 			}
 			.post {
 				font-size: 12px;
 				color: #757575;
 				text-transform: uppercase;
 				border-bottom: 1px solid #e2e6d9;
 				padding-bottom: 14px;
 				margin-bottom: 14px;
 			}
 			.social-media {
 				a {
 					margin-right: 40px;
 					color: #949497;
 					&:last-child {
 						margin-right: 0;
 					}
 					&:hover {
 						color: $darkcolor;
 					}
 				}
 			}
 		}
 	}
 	@media(max-width: 768px) {
 		.col-1-3 {
 			width: 50%;
 			.member-wrap {
 				margin-bottom: 20px;
 			}
 		}
 	}
 	@media(max-width: 480px) {
 		.col-1-3 {
 			width: 100%;
 		}
 	}
 }

 /* OUR CLIENTS SECTION */
 .our-clients {
 	padding: 80px 0 100px;
 	background-color: $gray;
 	text-align: center;
 	.body-text {
 		text-align: center;
 		&:last-of-type {
 			margin-bottom: 110px;
 		}
 	}
 	img {
 		opacity: .25;
 		&:hover {
 			opacity: .5;
 			transition: .4s;
 		}
 	}
 	@media(max-width: 768px) {
 		.col-1-6 {
 			width: 33.3333333333%;
 			img {
 				margin-bottom: 40px;
 			}
 		}
 	}
 	@media(max-width: 480px) {
 		.col-1-6 {
 			width: 50%;
 			img {
 				margin-bottom: 40px;
 			}
 		}
 	}
 }
